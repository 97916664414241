import React, { useEffect, useRef, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import tw, { css } from 'twin.macro'
import Cookies from 'js-cookie'

import { queryWordPress } from '../api'

const useDefaultTestimonials = () => {
  const data = useStaticQuery(graphql`
    query allTestimonials {
      allWpTestimonial {
        nodes {
          title
          content
          testimonialMeta {
            testimonialCity
            testimonialState
          }
          featuredImage {
            node {
              sourceUrl
              relativePath
            }
          }
        }
      }
    }
  `)

  return data.allWpTestimonial.nodes
}

const Carousel = () => {
  const defaultTestimonials = useDefaultTestimonials()
  const [activeSlide, setActiveSlide] = useState(0)
  const [loading, setLoading] = useState(true)

  const [slides, setSlides] = useState([])
  const slideRefs = useRef([])

  useEffect(() => {
    if (slides.length) return

    const locationCookie = Cookies.get('localResidentialBranchData')
    const userState = locationCookie
      ? JSON.parse(locationCookie).serviceAreaMeta.serviceAreaBranch[0]
          .branchMeta.branchOfficeState
      : null
    const whereClause = userState
      ? `(where: { testimonialState: "${userState}"})`
      : ''

    queryWordPress(`{
      testimonials${whereClause} {
        nodes {
          title
          content
          testimonialMeta {
            testimonialCity
            testimonialState
          }
          featuredImage {
            node {
              sourceUrl(size: MEDIUM)
              relativePath
            }
          }
        }
      }
    }`).then(({ data }) => {
      if (
        Array.isArray(data?.testimonials?.nodes) &&
        data.testimonials.nodes.length > 0
      ) {
        setSlides(data?.testimonials?.nodes)
      } else {
        setSlides(defaultTestimonials)
      }
      setActiveSlide(0)
    })
  }, [])

  useEffect(() => {
    if (slides.length > 0) {
      const timeout = setTimeout(() => {
        if (activeSlide < slides.length - 1) {
          setActiveSlide(activeSlide + 1)
        } else {
          setActiveSlide(0)
        }
      }, 7000)
      return () => {
        clearTimeout(timeout)
      }
    }
    setActiveSlide(0)
    return
  }, [slides, activeSlide])

  return !slides ? null : (
    <section tw="lg:(px-32) pt-16" aria-label="Testimonials">
      <div
        css={[
          tw`relative mb-8 overflow-hidden transition ease-in-out duration-500`,
          slideRefs.current[activeSlide] // does the current slide exist?
            ? `height: ${slideRefs.current[activeSlide].clientHeight}px;`
            : `height: 450px;
              @media (min-width: 767.98px) {
                height : 225px;
              }
              `,
        ]}
      >
        {slides.map((testimonial, i) => {
          const active = activeSlide === i
          return (
            <div
              ref={e => {
                slideRefs.current[i] = e
              }}
              key={`testimonial-slide-${i}`}
              css={[
                tw`flex w-full items-center absolute top-0 left-0 flex-col md:(flex-row)`,
                active && tw``,
              ]}
            >
              <div tw="mb-2 md:(mb-0 mr-4 text-left) text-center">
                <img
                  // https://www.456bereastreet.com/archive/201205/hiding_visible_content_from_screen_readers_with_aria-hidden/
                  aria-hidden="true"
                  src={testimonial?.featuredImage?.node?.relativePath}
                  width="200px"
                  height="200px"
                  loading="lazy"
                  css={[
                    'max-width:200px;',
                    tw`object-contain rounded-full transform translate-y-3 opacity-0 transition ease-in-out duration-500`,
                    active && tw`opacity-100 translate-y-0 opacity-100`,
                  ]}
                  loading="lazy"
                />
              </div>
              <blockquote
                tw="text-center text-gray-700"
                css={[
                  tw`transform -translate-x-3 opacity-0 transition ease-in-out duration-500 sm:m-0 md:(text-left)`,
                  active && tw`opacity-100 translate-x-0 opacity-100`,
                ]}
              >
                <div
                  tw="md:(text-xl) mb-4 leading-snug"
                  css={[
                    tw`md:(text-xl) mb-4 leading-snug`,
                    css`
                      @media (max-width: 768px) {
                        & p img {
                          margin: 0 auto;
                        }
                      }
                    `,
                  ]}
                  dangerouslySetInnerHTML={{
                    __html: testimonial.content,
                  }}
                />
                <cite tw="md:(text-lg) not-italic">
                  – <strong>{testimonial.title}</strong>,{' '}
                  {testimonial.testimonialMeta.testimonialCity},{' '}
                  {testimonial.testimonialMeta.testimonialState}
                </cite>
              </blockquote>
            </div>
          )
        })}
      </div>
      <div tw="flex items-center justify-center">
        {slides.map((slide, i) => {
          const active = activeSlide === i
          return (
            <button
              key={`testimonials-nav-button-${i}`}
              onClick={() => setActiveSlide(i)}
              css={[
                tw`w-5 h-5 border-4 border-yellow-600 mr-2 rounded-full focus:(outline-black) transition ease-in-out duration-150`,
                active && tw`bg-yellow-600`,
              ]}
              tabIndex={0}
            >
              <span tw="sr-only">
                {active ? 'current slide' : `select slide ${i + 1}`}
              </span>
            </button>
          )
        })}
      </div>
    </section>
  )
}

export default {
  Carousel,
}
